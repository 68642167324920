import React from 'react'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import { Helmet } from 'react-helmet'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from 'components/layout'
import Content from 'components/content'
import Section from 'components/section'
import H2 from 'components/typography/h2'
import { graphql, Link } from 'gatsby'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'

const AfterHoursSupportPage = ({
  data: {
    allSanityLocation: { nodes: allLocations },
    heroImage,
  },
}) => {
  return (
    <Layout>
      <Helmet>
        <title>After Hours Support | Hutson Inc</title>
      </Helmet>

      <HeroImage title='After Hours Support' image={heroImage}></HeroImage>

      <Content>
        <H2>Whenever, wherever. We've got you covered.</H2>
        <p>
          We're committed to keeping you running&mdash;whenever, wherever. If you need after hours
          support, give our after hours phone lines a call or send us a text.
        </p>
        <Grid>
          {allLocations.map(location => (
            <LocationContainer key={location.city}>
              <GatsbyImage
                image={location.images[0].asset.gatsbyImageData}
                alt={`${location.title} Hutson store`}
              />
              <LocationCopy>
                <LocationTitle>{location.title}</LocationTitle>
                {location.showPartsPhoneNumber && location.partsPhoneNumber ? (
                  <AfterHoursLine>
                    <AfterHoursLineLabel>After Hours Parts</AfterHoursLineLabel>
                    <a href={`tel:${location.partsPhoneNumber}`}>{location.partsPhoneNumber}</a>
                  </AfterHoursLine>
                ) : null}
                {location.showServicePhoneNumber && location.servicePhoneNumber ? (
                  <AfterHoursLine>
                    <AfterHoursLineLabel>After Hours Service</AfterHoursLineLabel>
                    <a href={`tel:${location.servicePhoneNumber}`}>{location.servicePhoneNumber}</a>
                  </AfterHoursLine>
                ) : null}
                <LocationLink to={location.slug}>View location hours and info</LocationLink>
              </LocationCopy>
            </LocationContainer>
          ))}
        </Grid>
      </Content>
    </Layout>
  )
}

const Grid = styled(Section)`
  ${clearfix}
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const LocationContainer = styled.div`
  border: 1px solid #d7d7d7;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 100%;

  @media (min-width: 600px) {
    ${column('1/2')}
  }

  @media (min-width: 800px) {
    ${column('1/3')}
  }

  @media (min-width: 1024px) {
    ${column('1/4')}
  }
`

const LocationCopy = styled.div`
  padding: 15px;
`

const LocationTitle = styled.h2`
  font-size: 1.5em;
`

const AfterHoursLine = styled.p`
  font-size: 1.25em;
  line-height: 1.2;
  margin: 8px 0 0;
  padding: 0;

  a {
    font-weight: 600;
    text-decoration: none;

    :hover,
    :focus {
      border-bottom: 1px solid #377539;
      text-decoration: none;
    }
  }
`

const AfterHoursLineLabel = styled.span`
  color: #333;
  display: block;
  font-size: 0.7em;
`

const LocationLink = styled(Link)`
  color: #377539;
  display: inline-block;
  font-size: 0.9em;
  margin-top: 8px;
  text-decoration: none;

  :hover,
  :focus {
    text-decoration: underline;
  }
`

export const pageQuery = graphql`
  query afterHoursSupportPage {
    heroImage: file(relativePath: { eq: "after-hours-support.jpg" }) {
      ...FullWidthImage
    }
    allSanityLocation(
      sort: { fields: title, order: ASC }
      filter: { retailLocation: { eq: true } }
    ) {
      nodes {
        city
        images {
          asset {
            gatsbyImageData(width: 300)
          }
        }
        partsPhoneNumber
        servicePhoneNumber
        showPartsPhoneNumber
        showServicePhoneNumber
        slug
        state
        title
      }
    }
  }
`

export default AfterHoursSupportPage
